var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout" }, [
    _c(
      "div",
      { staticClass: "column-left overview--list" },
      [
        _vm._l(_vm.filtered, function (item) {
          return _vm.filtered
            ? _c(
                "div",
                {
                  key: item.id,
                  staticClass: "overview__item--vacancy",
                  on: {
                    click: function ($event) {
                      return _vm.redirect(item.path)
                    },
                  },
                },
                [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _vm._v(" "),
                  item.first_label
                    ? _c("span", { staticClass: "label --small --grey" }, [
                        _vm._v(_vm._s(item.first_label)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.second_label
                    ? _c("span", { staticClass: "label --small --grey" }, [
                        _vm._v(_vm._s(item.second_label)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  item.third_label
                    ? _c("span", { staticClass: "label --small --grey" }, [
                        _vm._v(_vm._s(item.third_label)),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "label --small --grey orange uppercase" },
                    [
                      _c("img", {
                        attrs: { src: "/images/location-white.svg", alt: "" },
                      }),
                      _vm._v("  \n        " + _vm._s(item.residence)),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "text",
                    domProps: { innerHTML: _vm._s(item.text) },
                  }),
                ]
              )
            : _vm._e()
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("v-pagination", {
              attrs: { "page-count": _vm.pageCount },
              on: {
                paginate: function ($event) {
                  return _vm.scrollToTop()
                },
              },
              model: {
                value: _vm.currentPage,
                callback: function ($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card --green mobile" }, [
          _c("h3", { staticClass: "card__title" }, [
            _vm._v(_vm._s(_vm.openApplicationTitle)),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.openApplicationText))]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button -with-arrow",
              attrs: { href: _vm.openApplicationLink },
            },
            [
              _c("span", { staticClass: "button__text" }, [
                _vm._v(_vm._s(_vm.openApplicationButtonText)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "button__icon" }),
            ]
          ),
        ]),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "column-right" }, [
      _c("div", { staticClass: "card --blue form--filter" }, [
        _c("div", { staticClass: "card__header" }, [
          _c("h3", {
            staticClass: "card__title",
            domProps: { innerHTML: _vm._s(_vm.filterTitle) },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "card__action --close",
            domProps: { innerHTML: _vm._s(_vm.filterResetText) },
            on: {
              click: function ($event) {
                return _vm.reset()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filters.category,
                expression: "filters.category",
              },
            ],
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.filters,
                    "category",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.update,
              ],
            },
          },
          [
            _c("option", {
              attrs: { selected: "" },
              domProps: {
                value: null,
                innerHTML: _vm._s(_vm.filterCategories),
              },
            }),
            _vm._v(" "),
            _vm._l(_vm.categories, function (category) {
              return _c(
                "option",
                { key: category.id, domProps: { value: category.id } },
                [_vm._v(_vm._s(category.name))]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filters.education,
                expression: "filters.education",
              },
            ],
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.filters,
                    "education",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.update,
              ],
            },
          },
          [
            _c("option", {
              attrs: { selected: "" },
              domProps: { value: null, innerHTML: _vm._s(_vm.filterEducation) },
            }),
            _vm._v(" "),
            _vm._l(_vm.educations, function (education) {
              return _c(
                "option",
                { key: education, domProps: { value: education } },
                [_vm._v(_vm._s(education))]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filters.relation,
                expression: "filters.relation",
              },
            ],
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.filters,
                    "relation",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.update,
              ],
            },
          },
          [
            _c("option", {
              attrs: { selected: "" },
              domProps: { value: null, innerHTML: _vm._s(_vm.filterRelation) },
            }),
            _vm._v(" "),
            _vm._l(_vm.relations, function (relation) {
              return _c(
                "option",
                { key: relation.id, domProps: { value: relation.id } },
                [_vm._v(_vm._s(relation.name))]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card --green desktop" }, [
        _c("h3", {
          staticClass: "card__title",
          domProps: { innerHTML: _vm._s(_vm.openApplicationTitle) },
        }),
        _vm._v(" "),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.openApplicationText) } }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "button -with-arrow",
            attrs: { href: _vm.openApplicationLink },
          },
          [
            _c("span", {
              staticClass: "button__text",
              domProps: { innerHTML: _vm._s(_vm.openApplicationButtonText) },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "button__icon" }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }