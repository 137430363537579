export default class Video {

    /**
     * @param   {Element} element
     */
    constructor(element) {
        /**
         * @type {Element}
         * @private
         */
        this._container = element;

        /**
         * @type {Element | any}
         * @private
         */
        this._overlay = this._container.querySelector('.text');

        /**
         * @type {Element | any}
         * @private
         */
        this._playButton = this._container.querySelector('.play-button');

        /**
         * @type {Element | any}
         * @private
         */
        this._volume = this._container.querySelector('.volume');

        /**
         * @type {Element | any}
         * @private
         */
        this._video = this._container.querySelector('video');

    }

    init() {

        const self = this;

        this._video.controls = false;
        this._video.muted = true;

        this._overlay.addEventListener('click', () => {
            self.play();
        });

        this._playButton.addEventListener('click', () => {
            self.toggle();
        });

        this._volume.addEventListener('click', () => {
            self.volume();
        });

        this._video.onended = function() {
            self.ended();
        };
    }

    play() {
        this._container.classList.toggle('play');
        this._video.play();
    }

    volume() {
        this._volume.classList.toggle('on');
        this._video.muted = !this._video.muted;
    }

    toggle() {
        this._playButton.classList.toggle('-is-playing');

        if (this._video.paused) {
            this._video.play();
        } else {
            this._video.pause();
        }
    }

    ended() {
        this._container.classList.remove('play');
        this._video.pause();
        this._video.controls = false;
    }
}