<template>
    <div class="form green padding -centered-text">
        <div v-if="!success" class="form-field">
            <h4 class="title whatsapp-title" v-html="formTitle"></h4>
            <p class="center" v-html="formText"></p>
            <label class="center">
                <a href="https://wa.me/+31612826959" target="_blank" class="button green arrow" v-html="formSend"></a>
            </label>
        </div>
        <div v-else class="success-field">
            <img class="success-image" src="/images/success-blue.svg" alt="">
            <h4>Bedankt!</h4>
            <p>
                Jouw aanvraag is succesvol verzonden. We nemen zo snel mogelijk contact met je op via WhatsApp.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'whatsapp-form',
    props: [
      'vacancy',
      'formTitle',
      'formText',
      'formPhonenumber',
      'formPrivacy',
      'formSend'
    ],
    data: function () {
        return {
            success: false,
            phone: null,
            agree: false,
            privacyError: false,
            phoneError: false,
        }
    },
    methods: {
        validate: function () {
            this.phoneError = !this.phone;
            this.privacyError = !this.agree;

            if (this.phone !== null && this.agree) {
                this.send()
            }
        },
        send: function () {
            const _this = this;
            axios.post(
                '/form/whatsapp',
                {
                    'phone': this.phone,
                    'agree': this.agree,
                    'vacancy_id': this.vacancy
                }
            ).then(function (data) {
                _this.success = data.data.success
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.form {
    &.green {
        background-color: $green;
        color: white;
    }

    &.padding {
        padding: 44px;
    }

    h3 {
        margin-top: 0;
    }

    .center {
        text-align: center;

        a {
            text-decoration: none !important;

            &:hover {
                color: $green !important;
            }
        }
    }
}
</style>
