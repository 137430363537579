/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import NewsOverview from "./components/NewsOverview";

require('./bootstrap');
require('./onload');
require('./into-view');

import Slider from "./objects/slider.js";
import Video from "./objects/video.js";

$(() => {
    const sliders = document.querySelectorAll('.slider');
    const videos = document.querySelectorAll('.video');
    const topBar = document.querySelector('.topbar');
    const header = document.querySelector('nav');

    if ( header ) {
        document.addEventListener('scroll', () => {
            if (window.scrollY >= 100) {
                header.classList.add('--small');
            } else {
                header.classList.remove('--small');
            }
        });
    }

    if (topBar) {
        let firstRow = document.querySelector('.-page-header');

        document.addEventListener('scroll', () => {
            if (window.scrollY >= firstRow.offsetHeight - 110) {
                topBar.classList.add('active');
            } else {
                topBar.classList.remove('active');
            }
        });
    }

    if (sliders.length > 0) {
        sliders.forEach((slider) => {
            const object = new Slider(slider);
            object.init();
        });
    }

    if (videos.length > 0) {
        videos.forEach((video) => {
            const object = new Video(video);
            object.init();
        });
    }
});

window.Vue = require('vue');

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/VacancyOverview.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('vacancy-overview', require('./components/VacancyOverview.vue').default);
Vue.component('job-application-form', require('./components/JobApplicationForm.vue').default);
Vue.component('open-job-application-form', require('./components/OpenJobApplicationForm.vue').default);
Vue.component('whatsapp-form', require('./components/WhatsappForm.vue').default);
Vue.component('info-request', require('./components/InfoRequest.vue').default);
Vue.component('news-overview', NewsOverview);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});




// $('.-with-dropdown').on('click', function () {
//     if ($(this).children().hasClass('visible')) {
//         $(this).find('.dropdown').removeClass('visible');
//     } else {
//         $(this).find('.dropdown').removeClass('visible');
//         $(this).children().addClass('visible');
//     }
// });
