<template>
  <div>
    <div class="page" v-for="(postsPage, index) in data" :key="index" v-if="((index + 1) === page)">
      <div class="item column-4 column-sm-12 column-lg-6 column-xl-4 into-view" v-for="post in postsPage" :key="post.id">
        <a class="image" :href="post.path">
          <img :src="post.image" alt="">
        </a>
        <div class="bottom">
          <span class="date label orange uppercase">
            {{ post.created_at }}
          </span>
          <a class="title" :href="post.path">
            <h4>{{ post.name}}</h4>
          </a>
          <a :href="post.path" class="button -orange-border --arrow-right" v-html="buttonText">
            Bekijk bericht
          </a>
        </div>
      </div>
    </div>
    <div class="pagination --grey" v-if="(pageAmount > 1)">
      <v-pagination v-model="page" :page-count="pageAmount" @paginate="scrollToTop()"></v-pagination>
    </div>
  </div>
</template>

<script>
import vPagination from 'vue-plain-pagination';

export default {
  name: "news-overview",

  components: {
    vPagination
  },

  props: [
    'data',
    'buttonText'
  ],

  data() {
    return {
      'page': 1,
      'pageAmount': this.data.length,
    };
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },

  mounted() {
    console.log(this.data);
  }
}
</script>

<style scoped>

</style>
