<template>
  <div class="layout">
    <div class="column-left overview--list">
      <div v-if="filtered" v-for="item in filtered" :key="item.id" v-on:click="redirect(item.path)" class="overview__item--vacancy">
        <h3 class="title">{{ item.title }}</h3>

        <span class="label --small --grey" v-if="item.first_label">{{ item.first_label }}</span>
        <span class="label --small --grey" v-if="item.second_label">{{ item.second_label }}</span>
        <span class="label --small --grey" v-if="item.third_label">{{ item.third_label }}</span>
        <span class="label --small --grey orange uppercase">
          <img src="/images/location-white.svg" alt="">&nbsp;&nbsp;
          {{ item.residence }}</span>
        <br><br>
        <div v-html="item.text" class="text"></div>
      </div>
      <div class="pagination">
        <v-pagination v-model="currentPage" :page-count="pageCount" @paginate="scrollToTop()"></v-pagination>
      </div>
      <div class="card --green mobile">
        <h3 class="card__title">{{ openApplicationTitle}}</h3>
        <p>{{ openApplicationText }}</p>
        <a :href="openApplicationLink" class="button -with-arrow">
          <span class="button__text">{{ openApplicationButtonText }}</span>
          <span class="button__icon"></span>
        </a>
      </div>
    </div>
    <div class="column-right">
      <div class="card --blue form--filter">
        <div class="card__header">
          <h3 class="card__title" v-html="filterTitle"></h3>
          <span class="card__action --close" @click="reset()" v-html="filterResetText"></span>
        </div>

        <select v-model="filters.category"
                v-on:change="update">
          <option :value="null" selected v-html="filterCategories"></option>
          <option v-for="category in categories" :value="category.id" :key="category.id">{{ category.name }}</option>
        </select>
        <select v-model="filters.education" v-on:change="update">
          <option :value="null" selected v-html="filterEducation"></option>
          <option v-for="education in educations" :value="education" :key="education">{{ education }}</option>
        </select>
        <select v-model="filters.relation" v-on:change="update">
          <option :value="null" selected v-html="filterRelation"></option>
          <option v-for="relation in relations" :value="relation.id" :key="relation.id">{{ relation.name }}</option>
        </select>
      </div>
      <div class="card --green desktop">
        <h3 class="card__title" v-html="openApplicationTitle"></h3>
        <p v-html="openApplicationText"></p>
        <a :href="openApplicationLink" class="button -with-arrow">
          <span class="button__text" v-html="openApplicationButtonText"></span>
          <span class="button__icon"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import vPagination from 'vue-plain-pagination'

export default {
  name: 'vacancy-overview',
  components: {vPagination},
  props: [
    'vacatures',
    'categories',
    'educations',
    'relations',
    'pagecount',
    'openApplicationTitle',
    'openApplicationText',
    'openApplicationLink',
    'openApplicationButtonText',
    'filterTitle',
    'filterResetText',
    'filterCategories',
    'filterEducation',
    'filterRelation'
  ],
  data: function () {
    return {
      filtered: this.vacatures,
      filters: {
        category: null,
        education: null,
        relation: null
      },
      currentPage: 1,
      pageCount: this.pagecount
    }
  },
  methods: {
    update: function () {
      const _this = this;
      this.$http.post(
          '/vacancies/filter/' + this.currentPage,
          {
            category: this.filters.category,
            education: this.filters.education,
            relation: this.filters.relation
          }
      ).then((data) => {
        this.filtered = data.data;
        this.currentPage = 1;

        // window.history.pushState("", "", '/newpage');
      });

      this.axios.post(
          '/vacancies/count',
          {
            category: this.filters.category,
            education: this.filters.education,
            relation: this.filters.relation
          }
      ).then((data) => {
        this.pageCount = Math.ceil(data.data / 8)
      });
    },
    redirect: function (location) {
      console.log(location);
      window.location.href = location;
    },
    reset: function () {
      this.filters.category = null;
      this.filters.education = null;
      this.filters.relation = null;

      this.update();
    },
    scrollToTop: function () {
      function offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
      }

      const filter = document.querySelector('.form--filter');

      window.scrollTo(0, offset(filter).top);
    }
  },
  watch: {
    currentPage: function () {
      this.update()
    }
  }
}
</script>

<style lang="scss" scoped></style>
