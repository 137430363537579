var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form blue padding",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.validate.apply(null, arguments)
        },
      },
    },
    [
      !_vm.success
        ? _c("div", { staticClass: "form-field" }, [
            _c("h4", {
              staticClass: "title",
              domProps: { innerHTML: _vm._s(_vm.formTitle) },
            }),
            _vm._v(" "),
            _c("label", { staticClass: "half" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.first_name,
                    expression: "inputs.first_name",
                  },
                ],
                class: { error: _vm.first_nameError },
                attrs: {
                  type: "text",
                  name: "first_name",
                  placeholder: _vm.formFirstname + "*",
                },
                domProps: { value: _vm.inputs.first_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.inputs, "first_name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "half" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.last_name,
                    expression: "inputs.last_name",
                  },
                ],
                class: { error: _vm.last_nameError },
                attrs: {
                  type: "text",
                  name: "last_name",
                  placeholder: _vm.formSurname + "*",
                },
                domProps: { value: _vm.inputs.last_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.inputs, "last_name", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "half" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.email,
                    expression: "inputs.email",
                  },
                ],
                class: { error: _vm.emailError },
                attrs: {
                  type: "text",
                  name: "email",
                  placeholder: _vm.formEmail + "*",
                },
                domProps: { value: _vm.inputs.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.inputs, "email", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "half" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.phone,
                    expression: "inputs.phone",
                  },
                ],
                class: { error: _vm.phoneError },
                attrs: {
                  type: "tel",
                  name: "phone",
                  placeholder: _vm.formPhonenumber + "*",
                },
                domProps: { value: _vm.inputs.phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.inputs, "phone", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "file_upload" }, [
              _c("input", {
                attrs: { name: "cv", type: "file", id: "file_cv" },
                on: {
                  change: function ($event) {
                    return _vm.handleFileUpload($event, "cv")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", {
                class: { error: _vm.cvError },
                attrs: { for: "file_cv" },
                domProps: { innerHTML: _vm._s(_vm.formSelectFile) },
              }),
              _vm._v(" "),
              _c("label", {
                staticClass: "value-label",
                attrs: { for: "file_cv" },
                domProps: { innerHTML: _vm._s(_vm.formFileCv) },
              }),
            ]),
            _vm._v(" "),
            this.cvError
              ? _c("p", { staticClass: "error-text" }, [
                  _vm._v("Toegestane bestandstypen: pdf, jpg, png, doc, docx"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("label", { staticClass: "file_upload" }, [
              _c("input", {
                attrs: {
                  name: "motivation",
                  type: "file",
                  id: "file_motivation",
                },
                on: {
                  change: function ($event) {
                    return _vm.handleFileUpload($event, "motivation")
                  },
                },
              }),
              _vm._v(" "),
              _c("label", {
                class: { error: _vm.motivationError },
                attrs: { for: "file_motivation" },
                domProps: { innerHTML: _vm._s(_vm.formSelectFile) },
              }),
              _vm._v(" "),
              _c("label", {
                staticClass: "value-label",
                attrs: { for: "file_motivation" },
                domProps: { innerHTML: _vm._s(_vm.formFileMotivation) },
              }),
            ]),
            _vm._v(" "),
            this.motivationError
              ? _c("p", { staticClass: "error-text" }, [
                  _vm._v("Toegestane bestandstypen: pdf, jpg, png, doc, docx"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("label", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.comment,
                    expression: "inputs.comment",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "comment",
                  placeholder: _vm.formMessage,
                },
                domProps: { value: _vm.inputs.comment },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.inputs, "comment", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.inputs.privacy,
                    expression: "inputs.privacy",
                  },
                ],
                class: { error: _vm.privacyError },
                attrs: { type: "checkbox", name: "privacy", id: "privacy" },
                domProps: {
                  checked: Array.isArray(_vm.inputs.privacy)
                    ? _vm._i(_vm.inputs.privacy, null) > -1
                    : _vm.inputs.privacy,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.inputs.privacy,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.inputs, "privacy", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.inputs,
                            "privacy",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.inputs, "privacy", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("label", {
                attrs: { for: "privacy" },
                domProps: { innerHTML: _vm._s(_vm.formPrivacy) },
              }),
            ]),
            _vm._v(" "),
            _c("label", [
              _c("button", {
                staticClass: "button --orange green -with-arrow",
                attrs: { type: "submit" },
                domProps: { innerHTML: _vm._s(_vm.formSend) },
              }),
            ]),
          ])
        : _c("div", { staticClass: "success-field" }, [
            _c("img", {
              staticClass: "success-image",
              attrs: { src: "/images/success.svg", alt: "" },
            }),
            _vm._v(" "),
            _c("h1", [_vm._v("Bedankt voor je\n            sollicitatie!")]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Gelukt! Je sollicitatie is succesvol verstuurt. Wij nemen zo spoedig mogelijk contact met je op om je te\n            laten weten of je een geschikte kandidaat bent voor deze mooie job!\n            "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n            Nog vragen of per ongeluk iets niet juist ingevoerd? Neem dan contact met ons op.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "conversion-field" }, [
      _c(
        "a",
        {
          staticClass: "button --green --filled mail",
          attrs: { href: "mailto:info@vrdu.nl" },
        },
        [_vm._v("info@vrdu.nl")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "link", attrs: { href: "tel:31(0)184410960" } }, [
        _c("img", { attrs: { src: "/images/icon-phone.svg", alt: "" } }),
        _vm._v("\n                +31 (0)184 41 09 60\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }