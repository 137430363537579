<template>
    <form class="form blue padding" @submit.prevent="checkForm">
        <div class="form-field" v-if="!success">
            <h4 class="title" v-html="formTitle"></h4>
            <label class="half">
                <input type="text" class="half" name="first_name" v-model="inputs.first_name" :class="{error: first_nameError}"
                       :placeholder="formFirstname + '*'">
            </label>
            <label class="half">
                <input type="text" class="half" name="last_name" :class="{error: last_nameError}" v-model="inputs.last_name"
                       :placeholder="formSurname + '*'">
            </label>
            <label class="half">
                <input type="text" class="half" :class="{error: emailError}" name="email" v-model="inputs.email"
                       :placeholder="formEmail + '*'">
            </label>
            <label class="half">
                <input type="tel" class="half" name="phone" v-model="inputs.phone" :class="{error: phoneError}"
                       :placeholder="formPhonenumber + '*'">
            </label>

            <label class="file_upload">
                <input name="cv" v-on:change="handleFileUpload($event, 'cv')" type="file" id="file_cv">
                <label :class="{error: cvError}" for="file_cv" v-html="formSelectFile"></label>
                <label for="file_cv" class="value-label" v-html="formFileCv"></label>
            </label>

            <p class="error-text" v-if="this.cvError">Toegestane bestandstypen: pdf, jpg, png, doc, docx</p>

            <label class="file_upload">
                <input name="motivation" v-on:change="handleFileUpload($event, 'motivation')" type="file"
                       id="file_motivation">
                <label :class="{error: motivationError}" for="file_motivation" v-html="formSelectFile"></label>
                <label for="file_motivation" class="value-label" v-html="formFileMotivation"></label>
            </label>

            <p class="error-text" v-if="this.motivationError">Toegestane bestandstypen: pdf, jpg, png, doc, docx</p>

            <label>
                <textarea type="text" name="comment" v-model="inputs.comment"
                          :placeholder="formMessage"></textarea>
            </label>
            <label>
                <input type="checkbox" name="privacy" v-model="inputs.privacy" id="privacy" :class="{error: privacyError}">
                <label for="privacy" v-html="formPrivacy"></label>
            </label>
            <label>
                <button class="button --orange green -with-arrow" v-on:click="validate" type="submit" v-html="formSend">
                </button>
            </label>
            <p class="error-text -submit" v-if="this.formError">Het formulier kan niet worden verzonden, check bovenstaande velden.</p>
        </div>
        <div v-else class="success-field">
            <img class="success-image" src="/images/success.svg" alt="">
            <h1>Bedankt voor je
                sollicitatie!</h1>
            <p>
                Gelukt! Je sollicitatie is succesvol verstuurt. Wij nemen zo spoedig mogelijk contact met je op om je te laten weten of je een geschikte kandidaat bent voor deze mooie job!
                <br><br>
                Nog vragen of per ongeluk iets niet juist ingevoerd? Neem dan contact met ons op.
            </p>
            <div class="conversion-field">
                <a href="mailto:info@vrdu.nl" class="button --green --filled mail">info@vrdu.nl</a>
                <a href="tel:31(0)184410960" class="link">
                    <img src="/images/icon-phone.svg" alt="">
                    +31 (0)184 41 09 60
                </a>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: 'job-application-form',
    props: [
      'vacancy',
      'formTitle',
      'formFirstname',
      'formSurname',
      'formEmail',
      'formPhonenumber',
      'formSelectFile',
      'formFileCv',
      'formFileMotivation',
      'formMessage',
      'formPrivacy',
      'formSend'
    ],
    data: function () {
        return {
            success: false,
            inputs: {
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                comment: "",
                privacy: false,
                cv: null,
                motivation: null
            },

            formData: new FormData(),

            emailError: false,
            phoneError: false,
            first_nameError: false,
            last_nameError: false,
            privacyError: false,
            cvError: false,
            motivationError: false,
            formError: false,
        }
    },
    methods: {
        validate: function () {
            if (this.inputs.privacy) {
                this.prepare()
            }
        },
        prepare: function () {
            this.formData.append('vacancy', this.vacancy);
            this.formData.append('first_name', this.inputs.first_name);
            this.formData.append('last_name', this.inputs.last_name);
            this.formData.append('email', this.inputs.email);
            this.formData.append('phone', this.inputs.phone);
            this.formData.append('comment', this.inputs.comment);
            this.formData.append('privacy', this.inputs.privacy);

            this.send()
        },
        send: function () {
            const _this = this;
            this.axios.post(
                '/form/job-application',
                this.formData
            ).then(function (data) {
                _this.success = data.data.success
            })
        },
        handleFileUpload: function (e, name) {
            this.formData.append(name, e.target.files[0]);

            let type = e.target.files[0].type;

            console.log(type);

            if (!type.endsWith("pdf") && !type.endsWith("jpg") && !type.endsWith("jpeg") && !type.endsWith("png") && !type.endsWith("doc") && !type.endsWith("docx")) {
                if (name === 'cv') {
                    this.cvError = true;
                } else {
                    this.motivationError = true;
                }
            } else {
                if (name === 'cv') {
                    this.cvError = false;
                } else {
                    this.motivationError = false;
                }
            }

            // Zet de value in de label zodat je ziet dat je een bestand hebt geselecteerd. @menno
            const valueLabel = e.target.parentNode.querySelector('.value-label');
            if (valueLabel) {
              valueLabel.innerHTML = e.target.files[0].name;
            }
        },

        checkForm() {
            if(!this.inputs.email) {
                this.emailError = true;
            }  else this.emailError = !this.validEmail(this.inputs.email);

            this.phoneError = !this.inputs.phone;

            this.first_nameError = !this.inputs.first_name;
            this.last_nameError = !this.inputs.last_name;
            this.privacyError = !this.inputs.privacy;

            if(this.privacyError && this.last_nameError && this.first_nameError && this.phoneError ) {
                return true;
            }
        },

        validEmail: function (email) {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.form {
    &.blue {
        background-color: $blue;
        color: white;
    }

    h3 {
        margin-top: 0;
    }
}
</style>
