const CLASS_MOBILE_MENU_OPEN = 'mobile-menu-open';
const CLASS_STATE_OPEN = '-is-open';

export function toggleMobileMenu() {
  const app = document.getElementById('app');

  if (app.classList.contains(CLASS_MOBILE_MENU_OPEN)) {
    this.classList.remove(CLASS_STATE_OPEN);
    app.classList.remove(CLASS_MOBILE_MENU_OPEN);
  } else {
    app.classList.add(CLASS_MOBILE_MENU_OPEN);
    this.classList.add(CLASS_STATE_OPEN);
  }
}
