import {toggleMobileMenu} from "./mobile-menu";

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

/**
 * @param {NodeList} buttons
 */
function resetSubmenus(buttons) {
  buttons.forEach((element) => {
    if (element.classList.contains('-is-visible')) {
      element.classList.remove('-is-visible');
    }
  });
}

window.addEventListener('DOMContentLoaded', () => {
  const dropdownButtons = document.querySelectorAll('.button.-with-dropdown');

  if (dropdownButtons) {
    dropdownButtons.forEach((element) => {
      element.addEventListener('click', function (e) {
        if (this.classList.contains('-is-visible')) {
          this.classList.remove('-is-visible');
        } else {
          resetSubmenus(dropdownButtons);
          this.classList.add('-is-visible');
        }
      });
    });
  }
});

// Mobile menu
window.addEventListener('DOMContentLoaded', () => {
  const mobileSwitch = document.getElementById('mobile-menu-toggle');

  if (mobileSwitch) {
    mobileSwitch.addEventListener('click', toggleMobileMenu);
  }
});
