var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "left form-field" }, [
    !_vm.success
      ? _c("div", [
          _c("h4", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.formTitle) },
          }),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputs.company,
                  expression: "inputs.company",
                },
              ],
              class: { error: _vm.company_nameError },
              attrs: {
                type: "text",
                name: "company",
                placeholder: _vm.formCompany,
              },
              domProps: { value: _vm.inputs.company },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.inputs, "company", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputs.contact_person,
                  expression: "inputs.contact_person",
                },
              ],
              class: { error: _vm.contact_personError },
              attrs: {
                type: "text",
                name: "contact_person",
                placeholder: _vm.formContactperson,
              },
              domProps: { value: _vm.inputs.contact_person },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.inputs, "contact_person", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputs.email,
                  expression: "inputs.email",
                },
              ],
              class: { error: _vm.emailError },
              attrs: {
                type: "text",
                name: "email",
                placeholder: _vm.formEmail,
              },
              domProps: { value: _vm.inputs.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.inputs, "email", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputs.phone,
                  expression: "inputs.phone",
                },
              ],
              class: { error: _vm.phoneError },
              attrs: {
                type: "text",
                name: "phone",
                placeholder: _vm.formPhonenumber,
              },
              domProps: { value: _vm.inputs.phone },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.inputs, "phone", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("label", [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputs.comment,
                  expression: "inputs.comment",
                },
              ],
              class: { error: _vm.commentError },
              attrs: {
                type: "text",
                name: "comment",
                placeholder: _vm.formMessage,
              },
              domProps: { value: _vm.inputs.comment },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.inputs, "comment", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputs.privacy,
                  expression: "inputs.privacy",
                },
              ],
              class: { error: _vm.privacyError },
              attrs: { type: "checkbox", name: "privacy", id: "privacy" },
              domProps: {
                checked: Array.isArray(_vm.inputs.privacy)
                  ? _vm._i(_vm.inputs.privacy, null) > -1
                  : _vm.inputs.privacy,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.inputs.privacy,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.inputs, "privacy", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.inputs,
                          "privacy",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.inputs, "privacy", $$c)
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("label", {
              attrs: { for: "privacy" },
              domProps: { innerHTML: _vm._s(_vm.formPrivacy) },
            }),
          ]),
          _vm._v(" "),
          _c("label", [
            _c("button", {
              staticClass: "button green --orange arrow",
              attrs: { type: "submit" },
              domProps: { innerHTML: _vm._s(_vm.formSend) },
              on: { click: _vm.validate },
            }),
          ]),
        ])
      : _c("div", { staticClass: "success-field" }, [
          _c("img", {
            staticClass: "success-image",
            attrs: { src: "/images/success.svg", alt: "" },
          }),
          _vm._v(" "),
          _c("h1", [_vm._v("Bedankt voor je\n            aanvraag!")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Jouw aanvraag is succesvol verzonden. We nemen zo snel mogelijk contact met je op.\n            "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n            Nog vragen of per ongeluk iets niet juist ingevoerd? Neem dan contact met ons op.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "conversion-field" }, [
      _c(
        "a",
        {
          staticClass: "button --orange --filled mail",
          attrs: { href: "mailto:info@vrdu.nl" },
        },
        [_vm._v("info@vrdu.nl")]
      ),
      _vm._v(" "),
      _c("a", { staticClass: "link", attrs: { href: "tel:31(0)184410960" } }, [
        _c("img", { attrs: { src: "/images/icon-phone.svg", alt: "" } }),
        _vm._v("\n                +31 (0)85 007 07 74\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }