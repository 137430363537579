var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.data, function (postsPage, index) {
        return index + 1 === _vm.page
          ? _c(
              "div",
              { key: index, staticClass: "page" },
              _vm._l(postsPage, function (post) {
                return _c(
                  "div",
                  {
                    key: post.id,
                    staticClass:
                      "item column-4 column-sm-12 column-lg-6 column-xl-4 into-view",
                  },
                  [
                    _c(
                      "a",
                      { staticClass: "image", attrs: { href: post.path } },
                      [_c("img", { attrs: { src: post.image, alt: "" } })]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "bottom" }, [
                      _c(
                        "span",
                        { staticClass: "date label orange uppercase" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(post.created_at) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        { staticClass: "title", attrs: { href: post.path } },
                        [_c("h4", [_vm._v(_vm._s(post.name))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "button -orange-border --arrow-right",
                          attrs: { href: post.path },
                          domProps: { innerHTML: _vm._s(_vm.buttonText) },
                        },
                        [_vm._v("\n          Bekijk bericht\n        ")]
                      ),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.pageAmount > 1
        ? _c(
            "div",
            { staticClass: "pagination --grey" },
            [
              _c("v-pagination", {
                attrs: { "page-count": _vm.pageAmount },
                on: {
                  paginate: function ($event) {
                    return _vm.scrollToTop()
                  },
                },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }