<template>
    <div class="left form-field">
        <div v-if="!success">
            <h4 class="title" v-html="formTitle"></h4>
            <label>
                <input type="text" name="company" :placeholder="formCompany" v-model="inputs.company" :class="{error: company_nameError}">
            </label>
            <label>
                <input type="text" name="contact_person" :placeholder="formContactperson" v-model="inputs.contact_person" :class="{error: contact_personError}">
            </label>
            <label>
                <input type="text" name="email" :placeholder="formEmail" v-model="inputs.email" :class="{error: emailError}">
            </label>
            <label>
                <input type="text" name="phone" :placeholder="formPhonenumber" v-model="inputs.phone" :class="{error: phoneError}">
            </label>
            <label>
                <textarea type="text" name="comment" :placeholder="formMessage" v-model="inputs.comment" :class="{error: commentError}"></textarea>
            </label>
            <label>
                <input type="checkbox" name="privacy" id="privacy" v-model="inputs.privacy" :class="{error: privacyError}">
              <label for="privacy" v-html="formPrivacy"></label>
            </label>
            <label>
                <button class="button green --orange arrow" type="submit" @click="validate" v-html="formSend"></button>
            </label>
        </div>
        <div v-else class="success-field">
            <img class="success-image" src="/images/success.svg" alt="">
            <h1>Bedankt voor je
                aanvraag!</h1>
            <p>
                Jouw aanvraag is succesvol verzonden. We nemen zo snel mogelijk contact met je op.
                <br><br>
                Nog vragen of per ongeluk iets niet juist ingevoerd? Neem dan contact met ons op.
            </p>
            <div class="conversion-field">
                <a href="mailto:info@vrdu.nl" class="button --orange --filled mail">info@vrdu.nl</a>
                <a href="tel:31(0)184410960" class="link">
                    <img src="/images/icon-phone.svg" alt="">
                    +31 (0)85 007 07 74
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'info-request',
        props: [
            'translations',
            'formTitle',
            'formCompany',
            'formContactperson',
            'formEmail',
            'formPhonenumber',
            'formMessage',
            'formPrivacy',
            'formSend'
        ],
        data: function () {
            return {
                success: false,
                inputs: {
                    company: "",
                    contact_person: "",
                    email: "",
                    phone: "",
                    comment: "",
                    privacy: false,
                },
                formData: new FormData(),

                company_nameError: false,
                contact_personError: false,
                emailError: false,
                phoneError: false,
                commentError: false,
                privacyError: false,
            }
        },
        methods: {

            prepare: function () {
                this.formData.append('company', this.inputs.company);
                this.formData.append('contact_person', this.inputs.contact_person);
                this.formData.append('email', this.inputs.email);
                this.formData.append('phone', this.inputs.phone);
                this.formData.append('comment', this.inputs.comment);
                this.formData.append('privacy', this.inputs.privacy);

                this.send();
            },

            send: function () {
                const _this = this;
                this.axios.post(
                    '/form/information',
                    this.formData
                ).then(function (data) {
                    _this.success = data.data.success;
                });
            },

            validate() {

                // if(!this.inputs.email) {
                //     this.emailError = true;
                // }  else this.emailError = !this.validEmail(this.inputs.email);
                //
                // this.phoneError = !this.inputs.phone;
                this.privacyError = !this.inputs.privacy;

                if(!this.privacyError) {
                    this.prepare();
                }
            },

            validEmail: function (email) {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "resources/sass/variables";

    .form {
        &.blue {
            background-color: $blue;
            color: white;
        }

        &.padding {
            padding: 44px;
        }

        h3 {
            margin-top: 0;
        }
    }
</style>
