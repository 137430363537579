$(() => {
    const containers = document.querySelectorAll(".container");
    const rows = document.querySelectorAll("main .row");
    const footer = document.querySelector("footer");
    const newsItems = document.querySelectorAll(".news .item");

    intoView();

    document.addEventListener("scroll", function () {
        intoView();
    });

    function intoView() {

        containers.forEach(field => {

            const field_top_position = field.getBoundingClientRect().top - window.innerHeight;
            const field_bottom_position = field.getBoundingClientRect().bottom - window.innerHeight;

            if (field_top_position < -150 || field_bottom_position < 0) {
                field.classList.add("into-view");
            }
        });

        rows.forEach(field => {

            const field_top_position = field.getBoundingClientRect().top - window.innerHeight;
            const field_bottom_position = field.getBoundingClientRect().bottom - window.innerHeight;

            if (field_top_position < -150 || field_bottom_position < 0) {
                field.classList.add("into-view");
            }
        });

        newsItems.forEach(field => {

            const field_top_position = field.getBoundingClientRect().top - window.innerHeight;
            const field_bottom_position = field.getBoundingClientRect().bottom - window.innerHeight;

            if (field_top_position < -150 || field_bottom_position < 0) {
                field.classList.add("into-view");
            }
        });

        const field_top_position = footer.getBoundingClientRect().top - window.innerHeight;
        const field_bottom_position = footer.getBoundingClientRect().bottom - window.innerHeight;

        if (field_top_position < -150 || field_bottom_position < 0) {
            footer.classList.add("into-view");
        }
    }
});