var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form green padding -centered-text" }, [
    !_vm.success
      ? _c("div", { staticClass: "form-field" }, [
          _c("h4", {
            staticClass: "title whatsapp-title",
            domProps: { innerHTML: _vm._s(_vm.formTitle) },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "center",
            domProps: { innerHTML: _vm._s(_vm.formText) },
          }),
          _vm._v(" "),
          _c("label", { staticClass: "center" }, [
            _c("a", {
              staticClass: "button green arrow",
              attrs: { href: "https://wa.me/+31612826959", target: "_blank" },
              domProps: { innerHTML: _vm._s(_vm.formSend) },
            }),
          ]),
        ])
      : _c("div", { staticClass: "success-field" }, [
          _c("img", {
            staticClass: "success-image",
            attrs: { src: "/images/success-blue.svg", alt: "" },
          }),
          _vm._v(" "),
          _c("h4", [_vm._v("Bedankt!")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n            Jouw aanvraag is succesvol verzonden. We nemen zo snel mogelijk contact met je op via WhatsApp.\n        "
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }